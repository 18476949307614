import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_ADEPT_DATA } from 'Consts/routes';
import { 
    API_RESOURCE_USERS, 
} from 'Consts/apiResources';
import {
    USER_ROLE_ADEPT,
} from 'Consts/userRoles';
import {
    TRAINING_TARGET_OPTIONS,
    PREVIOUS_EXPERIENCE_OPTIONS,
    TRAINED_WITH_PERSONAL_TRAINER_OPTIONS,
    NUTRITION_OPTIONS,
} from 'Consts/preferencesSurvey';

import { fromSelectObject } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getFullName } from 'Utils/user';
import { toApiFormat as dateToApiFormat } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import { TAB_PREFERENCES_SURVEYS } from 'Components/pages/admin/AdeptData/component';

export default class AdminUserEffectEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
        }).isRequired,
    };

    state = {
        formState: {},
    };

    formStateToData = formState => {
        return {
            ...formState,
            nutrition: fromSelectObject(formState.nutrition),
            previousExperience: fromSelectObject(formState.previousExperience),
            trainedWithPersonalTrainer: fromSelectObject(formState.trainedWithPersonalTrainer),
            trainingTarget: fromSelectObject(formState.trainingTarget),
            userId: fromSelectObject(formState.userId),
            startDate: dateToApiFormat(formState.startDate),
            leadContraindications: formState.leadContraindications || 'Brak',
            motionSystem: formState.motionSystem || 'Brak',
        };
    }

    onSubmit = formState => {
        return this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...this.formStateToData(formState),
        }).then(() => {
            history.push(
                withVariables(
                    ADMIN_ADEPT_DATA.path, 
                    { }, 
                    { tab: TAB_PREFERENCES_SURVEYS }
                )
            );
        })
            .catch(error => {
                Object.keys(error.payload.validationErrors).map(fieldName => {
                    error.payload.validationErrors[fieldName].map(errorMessage => {
                        toast.error(`${errorMessage}`);
                    });
                });

                throw error;
            });
    }

    render() {
        const { location, history, actions } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="admin-user-effect-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'select',
                            name: 'userId',
                            label: 'Adept',
                            required: true,
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                    role: USER_ROLE_ADEPT
                                }),
                                onChange: value => this.setState(prevState => ({
                                    formState: {
                                        ...prevState.formState,
                                        userId: fromSelectObject(value)?.value,
                                    },
                                })),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getFullName(element).label,
                                }),
                            },
                        }, {
                            type: 'select',
                            name: 'trainingTarget',
                            label: 'Główny cel treningowy',
                            options: TRAINING_TARGET_OPTIONS.map(option => ({
                                value: option.label,
                                label: option.label,
                            })),
                            required: true,
                        }, {
                            type: 'input',
                            name: 'leadContraindications',
                            label: 'Czy są choroby, operacje lub przeciwwskazania, o których terapeuta powinien wiedzieć?',
                        }, {
                            type: 'input',
                            name: 'motionSystem',
                            label: 'Czy odczuwasz ból lub dyskomfort związany z układem ruchu?',
                        }, {
                            type: 'select',
                            name: 'previousExperience',
                            label: 'Jak oceniasz swoje doświadczenie w aktywności fizycznej?',
                            required: true,
                            options: PREVIOUS_EXPERIENCE_OPTIONS.map(option => ({
                                value: option.label,
                                label: option.label,
                            }))
                        }, {
                            type: 'select',
                            name: 'trainedWithPersonalTrainer',
                            label: 'Czy ćwiczyłeś już pod okiem fizjoterapeuty lub trenera personalnego?',
                            options: TRAINED_WITH_PERSONAL_TRAINER_OPTIONS.map(option => ({
                                value: option.label,
                                label: option.label,
                            })),
                            required: true,
                        }, {
                            type: 'input',
                            name: 'userCity',
                            label: 'Podaj swoje miasto',
                            required: true,
                        }, {
                            type: 'input',
                            name: 'userAge',
                            label: 'Ile masz lat?',
                            required: true,
                        }, {
                            type: 'datePicker',
                            name: 'startDate',
                            label: 'Od kiedy chcesz zacząć?',
                            inputProps: {
                                datePickerProps: {
                                    dateFormat: 'dd.MM.yyyy',
                                    showTimeSelect: false,
                                },
                            },
                            required: true,
                        }, {
                            type: 'select',
                            name: 'nutrition',
                            label: 'Która z poniższych pozycji najlepiej opisuje Twoją obecną dietę?',
                            options: NUTRITION_OPTIONS.map(option => ({
                                value: option.label,
                                label: option.label,
                            })),
                            required: true,
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}
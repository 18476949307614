import { connect } from 'react-redux';
import Component from './component';

import {
    create,
} from 'Redux/modules/admin/userSurveys/actions';
import { list as listUsers } from 'Redux/modules/admin/users/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            create: dispatch(create),
            listUsers: dispatch(listUsers),
        },
    }),
)(Component);
export const TRAINING_TARGET_REHAB = 'rehab';
export const TRAINING_TARGET_SLIMMING = 'slimming';
export const TRAINING_TARGET_BODY_SHAPE_IMPROVEMENT = 'bodyShapeImprovement';
export const TRAINING_TARGET_PAIN = 'pain';
export const TRAINING_TARGET_RETURN_TO_ACTIVITY = 'returnToActivity';
export const TRAINING_TARGET_BODY_BUILDING = 'bodyBuilding';
export const TRAINING_TARGET_ENDURANCE = 'endurance';
export const TRAINING_TARGET_SPORTS_RESULTS_IMPROVEMENT = 'sportsResultsImprovement';
export const TRAINING_TARGET_OPTIONS = [{
    key: TRAINING_TARGET_REHAB,
    label: 'Chcę rozpocząć rehabilitację',
}, {
    key: TRAINING_TARGET_SLIMMING,
    label: 'Chcę schudnąć',
}, {
    key: TRAINING_TARGET_BODY_SHAPE_IMPROVEMENT,
    label: 'Chcę poprawić sylwetkę',
}, {
    key: TRAINING_TARGET_PAIN,
    label: 'Chcę pozbyć się dolegliwości bólowych',
}, {
    key: TRAINING_TARGET_RETURN_TO_ACTIVITY,
    label: 'Chcę wrócić do aktywności fizycznej',
}, {
    key: TRAINING_TARGET_BODY_BUILDING,
    label: 'Chcę zwiększyć siłę i masę mięśniową',
}, {
    key: TRAINING_TARGET_ENDURANCE,
    label: 'Chcę poprawić wydolność i wytrzymałość',
}, {
    key: TRAINING_TARGET_SPORTS_RESULTS_IMPROVEMENT,
    label: 'Chcę poprawić wyniki sportowe',    
}];

export const PREVIOUS_EXPERIENCE_LOW = 'low';
export const PREVIOUS_EXPERIENCE_MID = 'mid';
export const PREVIOUS_EXPERIENCE_HIGH = 'high';
export const PREVIOUS_EXPERIENCE_OPTIONS = [{
    key: PREVIOUS_EXPERIENCE_LOW,
    label: 'Niewielkie',
}, {
    key: PREVIOUS_EXPERIENCE_MID,
    label: 'Przeciętne',
}, {
    key: PREVIOUS_EXPERIENCE_HIGH,
    label: 'Duże',
}];

export const TRAINED_WITH_PERSONAL_TRAINER_PHYSIO = 'physio';
export const TRAINED_WITH_PERSONAL_TRAINER_PERSONAL = 'personal';
export const TRAINED_WITH_PERSONAL_TRAINER_NONE = 'none';
export const TRAINED_WITH_PERSONAL_TRAINER_OPTIONS = [{
    key: TRAINED_WITH_PERSONAL_TRAINER_PHYSIO,
    label: 'Tak - z fizjoterapeutą',
}, {
    key: TRAINED_WITH_PERSONAL_TRAINER_PERSONAL,
    label: 'Tak - z trenerem personalnym',
}, {
    key: TRAINED_WITH_PERSONAL_TRAINER_NONE,
    label: 'Nie',
}];

export const NUTRITION_BALANCED = 'balanced';
export const NUTRITION_VEGE = 'vege';
export const NUTRITION_PALEO_KETO = 'paleoKeto';
export const NUTRITION_DIET_CATERING = 'dietCatering';
export const NUTRITION_SUPPORT_NEEDED = 'supportNeeded';
export const NUTRITION_OPTIONS = [
    {
        key: NUTRITION_BALANCED,
        label: 'Zbilansowana',
    },
    {
        key: NUTRITION_VEGE,
        label: 'Wegetariańska / wegańska',
    },
    {
        key: NUTRITION_PALEO_KETO,
        label: 'Paleo / keto',
    },
    {
        key: NUTRITION_DIET_CATERING,
        label: 'Mam katering dietetyczny',
    },
    {
        key: NUTRITION_SUPPORT_NEEDED,
        label: 'Potrzebuję wsparcia w zakresie żywienia',
    },
];